<template>
  <div class="footer">
    <div class="row">
      <div class="col-4">
        <img
          @click="handleRouter('Home')"
          src="https://catsitterindonesia.com/image/logo.7e9d97f2.png"
          alt=""
        />
        <p class="tagline mb-0">
          Connecting Sitters with Pawrents
        </p>

        <p class="title mb-0">HEAD OFFICE</p>
        <p @click="handleLink('Address')" class="text mb-0 address">One Pacific Place Lt. 15, Jl. Jend. Sudirman Kav. 52-53, RT.5/RW.3, Kecamatan Kebayoran Baru, Jakarta Selatan, Indonesia 12190</p>

        <p class="title mb-0">CUSTOMER CARE</p>
        <p v-if="links.length" @click="handleLink('Email')" class="text contact mb-0">
          <SvgIcon type="mdi" :path="iconPath[0]" />
          {{ links.find((item) => item.type === 'email').url.replace(/^mailto:/, '') }}
        </p>
        <p v-if="links.length" @click="handleLink('Phone')" class="text contact mb-0">
          <SvgIcon type="mdi" :path="iconPath[1]" />
          {{ links.find((item) => item.type === 'telephone').url.replace(/^tel:/, '') }}
        </p>
      </div>
      <div class="col-8 text-end">
        <ul>
          <li @click="handleRouter('Services')">Services</li>
          <li @click="handleRouter('Profile')">Cat Sitter Profile</li>
          <li @click="handleLink('Blog')">Blog</li>
          <li @click="handleLink('Career')">Career</li>
          <b-button @click="handleLink('Contact')" class="btn-contact">
            Contact Us
          </b-button>
        </ul>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-12 col-lg-9">
        <p class="copyright mb-0">
          Copyright © 2024 PT. Gato Integrated Solution
        </p>
      </div>
      <div class="col-12 col-lg-3 text-center text-lg-end">
        <SvgIcon
          @click="handleLink('Instagram')"
          class="social-media ig"
          type="mdi"
          :path="iconPath[2]"
        />
        <SvgIcon
          @click="handleLink('Linkedin')"
          class="social-media li"
          type="mdi"
          :path="iconPath[3]"
        />
        <svg
          @click="handleLink('Tiktok')"
          class="social-media wa"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"
            fill="#fff"
          />
        </svg>
        <SvgIcon
          @click="handleLink('Contact')"
          class="social-media wa"
          type="mdi"
          :path="iconPath[4]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import {
  mdiEmail,
  mdiPhone,
  mdiInstagram,
  mdiLinkedin,
  mdiWhatsapp,
} from '@mdi/js'

export default {
  name: 'FooterComponent',
  components: {
    SvgIcon,
  },
  props: {
    links: {
      type: Array,
    },
  },
  data() {
    return {
      iconPath: [mdiEmail, mdiPhone, mdiInstagram, mdiLinkedin, mdiWhatsapp],
    }
  },
  methods: {
    handleLink(source) {
      switch (source) {
        case 'Email':
          return window.open(
            this.links.find((item) => item.type === 'email').url,
            '_blank',
          )
        case 'Phone':
          return window.open(
            this.links.find((item) => item.type === 'telephone').url,
            '_blank',
          )
        case 'Instagram':
          return window.open(
            this.links.find((item) => item.type === 'instagram').url,
            '_blank',
          )
        case 'Linkedin':
          return window.open(
            this.links.find((item) => item.type === 'linkedin').url,
            '_blank',
          )
        case 'Blog':
          return window.open('https://blog.catsitterindonesia.com/', '_blank')
        case 'Career':
          return window.open(
            this.links.find((item) => item.type === 'career').url,
            '_blank',
          )
        case 'Contact':
          return window.open(
            this.links.find((item) => item.type === 'whatsapp').url,
            '_blank',
          )
        case 'Tiktok':
          return window.open(
            this.links.find((item) => item.type === 'tiktok').url,
            '_blank',
          )
        case 'Address':
          return window.open(
            'https://maps.app.goo.gl/6iZmmBFDf9AuXShc8',
            '_blank',
          )
        default:
          return
      }
    },
    handleRouter(route) {
      switch (route) {
        case 'Home':
          return this.$router.push({ name: 'Home' })
        case 'Profile':
          return this.$router.push({ name: 'Profile' })
        case 'Services':
          if (this.$route.name !== 'Home') {
            this.$router.push({ name: 'Home' })
          }

          return this.$emit('trigger-services')
        default:
          return
      }
    },
  },
}
</script>

<style scoped>
.footer {
  margin-top: 5.21vw;
  background: #ff5a2a;
  padding: 3.13vw 10vw;
}

.footer img {
  cursor: pointer;
  max-width: 17vw;
}

.footer .tagline {
  color: #fff;
  font-size: 1.25vw;
  font-weight: 600;
  margin-top: 0.52vw;
}

.footer .title {
  color: #fff;
  font-size: 0.83vw;
  font-weight: 600;
  margin-top: 1.56vw;
}

.footer .address:hover{
  cursor: pointer;
}

.footer .text {
  color: #fff;
  font-size: 0.83vw;
  margin-top: 0.26vw;
}

.footer .contact {
  cursor: pointer;
}

.footer .contact:hover {
  color: #fdb23f;
}

.footer ul {
  list-style-type: none;
  padding: 0;
}

.footer ul li {
  display: inline;
  color: #fff;
  font-size: 1.25vw;
  cursor: pointer;
  margin-right: 2.6vw;
}

.footer ul li:hover {
  color: #fdb23f;
}

.footer .btn-contact {
  padding: 0.63vw 1.25vw;
  background: #fff;
  color: #ff5a2a;
  font-weight: 600;
  font-size: 1.04vw;
  border: 0;
  border-radius: 2.5vw;
}

.footer .btn-contact:hover,
.footer .btn-contact:active {
  background: linear-gradient(90deg, #fdb23f, #ff5a2a);
  color: #fff;
}

.footer hr {
  border: 0;
  border-top: 1px solid #fff;
  margin: 1.04vw 0;
}

.footer .copyright {
  color: #fff;
  font-size: 1.25vw;
}

.footer .social-media {
  color: #fff;
  font-size: 1.25vw;
  margin-left: 0.63vw;
  height: 1.25vw;
}

.footer .social-media:hover {
  cursor: pointer;
}

.footer .ig:hover {
  color: #833AB4;
}

.footer .li:hover {
  color: #0b65c2;
}

.footer .tt:hover {
  color: #000 !important;
}

.footer .wa:hover {
  color: #25d366;
}

/* mobile */
@media (max-width: 991.98px) {
  .footer {
    padding: 5vw;
  }

  .footer .col-4,
  .footer .col-8 {
    width: 100%;
  }

  .footer .col-8 {
    text-align: left !important;
    margin-top: 10vw;
  }

  .footer img {
    width: auto;
    max-width: 50vw;
  }

  .footer .tagline {
    color: #fff;
    font-size: 4vw;
    margin-top: 2vw;
  }

  .footer .title {
    font-size: 4vw;
    margin-top: 6vw;
  }

  .footer .text {
    font-size: 4vw;
    margin-top: 1vw;
  }

  .footer ul li {
    font-size: 4vw;
    margin-right: 2.6vw;
  }

  .footer ul li:hover {
    color: #fdb23f;
  }

  .footer .btn-contact {
    padding: 2vw 4vw;
    font-size: 3vw;
    border-radius: 10vw;
  }

  .footer hr {
    margin: 4vw 0;
  }

  .footer .copyright {
    color: #fff;
    font-size: 4vw;
  }

  .footer .social-media {
    font-size: 4vw;
    margin-left: 2vw;
    height: 4vw;
  }
}
</style>
