<template>
  <div>
    <Loader v-if="isLoading" color="#000" />
    <div class="admin">
      <div class="logo row">
        <div class="col-12 text-center">
          <img src="https://catsitterindonesia.com/image/logo.7e9d97f2.png" alt="logo" draggable="false" />
        </div>
      </div>
      <div class="login d-flex">
        <div class="left">
          <b-form>
            <b-form-group
              label="Username"
              description="Don't ever share your username with anyone."
            >
              <b-form-input
                v-model="form.username"
                type="text"
                placeholder="Enter username"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Password"
              description="Don't ever share your password with anyone."
            >
              <b-form-input
                v-model="form.password"
                type="password"
                placeholder="Enter password"
              ></b-form-input>
            </b-form-group>

            <b-button @click="handleLogin">Login</b-button>
          </b-form>
        </div>
        <div class="right text-center align-content-center">
          <p class="quote">
            {{ `"${quote.quote}"` }}
          </p>
          <p class="author">
            {{ quote.author }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import api from '@/api'
import Loader from '@/landing/components/Loader.vue'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

export default {
  name: 'LoginPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      quote: {},
      form: {
        username: '',
        password: '',
      },
    }
  },
  created() {
    const token = localStorage.getItem('token')
    if (token) {
      this.$router.push({ name: 'Dashboard' })
    }
  },
  mounted() {
    this.getQuotes()
  },
  methods: {
    async getQuotes() {
      await axios
        .get('https://quotes-api-self.vercel.app/quote')
        .then((response) => {
          this.quote = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async handleLogin() {
      await api
        .post('login', this.form)
        .then((response) => {
          const token = response.data.token
          localStorage.setItem('token', token)

          toast('Login success', {
            position: 'top-right',
            type: 'success',
            autoClose: 1000,
          })

          setTimeout(() => {
            this.$router.push({ name: 'Dashboard' })
          }, 1000)
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.admin {
  background-image: radial-gradient(
      circle at 20% 60%,
      #8590c8 5%,
      transparent 30%
    ),
    linear-gradient(45deg, #ff5a2a, #fdb23f);
  height: 100vh;
}

.logo {
  padding-top: 5vw;
}

.logo img {
  max-width: 30vw;
}

.login {
  margin: 5vw 20vw 0 20vw;
  border-radius: 1vw;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.left {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1vw 0 0 1vw;
  padding: 5vw;
  width: 60%;
}

.left .btn {
  background: #ff5a2a;
  border: 1px solid #ff5a2a;
  color: #fff;
}

.left .btn:hover,
.left .btn:active,
.left .btn:active {
  background: #ff5a2a;
  border-color: #ff5a2a;
  box-shadow: 0 8px 25px -8px #ff5a2a;
}

.right {
  background: rgba(255, 90, 42, 0.2);
  border-radius: 0 1vw 1vw 0;
  padding: 5vw;
  width: 40%;
  color: #fff;
}

.right .quote,
.right .author {
  font-size: 1.25vw;
}

.right .author {
  font-weight: bold;
}
</style>
