<template>
  <div class="section">
    <img src="https://catsitterindonesia.com/image/logo.7e9d97f2.png" class="logo" alt="logo" draggable="false" />

    <div
      @click="isAccountShow = !isAccountShow"
      :class="{ account: true, show: isAccountShow }"
    >
      <img
        src="https://cdn.dribbble.com/users/4949363/avatars/normal/606bb85ee728fd3d78bbddf7e70b3901.jpg?1676454777"
        alt="logo"
        draggable="false"
      />
      <div class="description">
        <p class="title mb-0">Account</p>
        <p class="username mb-0">catsitin_admin</p>
      </div>
      <p class="mb-0 ms-auto align-content-center">V</p>
    </div>
    <div @click="handleLogout()" class="account-sub">
      <p class="mb-0">Log Out</p>
    </div>
    <ul>
      <li
        v-for="(item, index) in route"
        :key="index"
        @click="goToRoute(item.name)"
        :class="{ active: this.$route.name === item.name }"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import api from '@/api'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

export default {
  name: 'SidebarComponent',
  data() {
    return {
      isAccountShow: false,
      route: [],
    }
  },
  created() {
    this.route = this.$router.options.routes.find(
      (route) => route.name === 'Template',
    ).children
    const token = localStorage.getItem('token')
    if (!token) {
      this.$router.push({ name: 'Login' })
    }
  },
  methods: {
    async handleLogout() {
      await api
        .get('logout')
        .then(() => {
          localStorage.removeItem('token')
          toast('Logout success', {
            position: 'top-right',
            type: 'success',
            autoClose: 1000,
          })

          setTimeout(() => {
            this.$router.push({ name: 'Login' })
          }, 1000)
        })
        .catch((error) => {
          console.error(error)
          toast('Logout failed', {
            position: 'top-right',
            type: 'error',
            autoClose: 1000,
          })
        })
    },
    goToRoute(route) {
      this.$router.push({ name: route })
    },
  },
}
</script>

<style scoped>
.section .account .description p,
.section ul li {
  font-size: 12px;
}

.section {
  width: 20%;
  padding-right: 20px;
}

.section .logo {
  width: 100%;
  margin-bottom: 5vh;
}

.section .account {
  background: #ff5a2a;
  padding: 10px;
  border-radius: 10px;
  display: flex;
}

.section .account:hover {
  cursor: pointer;
}

.section .account img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
}

.section .account .description .title,
.section .account .description .username,
.section .account .ms-auto {
  color: #f8eee2;
}

.section .account-sub {
  display: none;
  background: #dc3545;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  text-align: center;
}

.section .account-sub:hover {
  cursor: pointer;
  box-shadow: 0 8px 25px -8px #dc3545;
}

.section .account-sub p {
  color: #f8eee2;
  font-size: 12px;
}

body:has(.section .account.show) .account-sub {
  display: block;
}

.section ul {
  list-style: none;
  padding-left: 0;
  width: 100%;
  margin-top: 10vh;
}

.section ul li {
  padding: 10px;
  border-radius: 10px;
  color: #f8eee2;
  margin-bottom: 5px;
  transition: 0.5s;
}

.section ul li:hover {
  cursor: pointer;
  background: rgba(255, 90, 42, 0.6);
  color: #fff;
}

.section ul li.active {
  background: #ff5a2a;
  color: #fff;
}

.section ul li.active:hover {
  cursor: default;
}

@media (min-width: 1440px) {
  .section {
    padding-right: 80px;
  }
}
</style>
