<template>
  <div>
    <b-navbar
      class="fixed-top"
      ref="navbar"
      :class="{ sticky: isScroll }"
      toggleable="lg"
      type="light"
    >
      <img
        @click="handleLink('Home')"
        :src="isScroll ? 'https://catsitterindonesia.com/image/logoorange.75f6438e.png' : 'https://catsitterindonesia.com/image/logo.7e9d97f2.png'"
        alt=""
      />

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item @click="handleLink('Services')">
            {{ this.lang === 'id' ? 'Layanan' : 'Services' }}
          </b-nav-item>
          <b-nav-item @click="handleLink('Profile')">
            {{ this.lang === 'id' ? 'Profil Cat Sitter' : 'Cat Sitter Profile' }}
          </b-nav-item>
          <b-nav-item @click="handleLink('Blog')">Blog</b-nav-item>
          <b-nav-item @click="handleLink('Career')">
            {{ this.lang === 'id' ? 'Karir' : 'Career' }}
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ms-auto align-items-center">
          <div class="lang justify-content-center align-items-center d-flex">
            <p class="mb-0">ID</p>
            <b-form-checkbox
              v-model="isLangActive"
              switch
              @change="handleLangOnChange()"
              class="mx-2"
            ></b-form-checkbox>
            <p class="mb-0">EN</p>
          </div>
          <b-button
            @click="handleLink('Contact')"
            class="btn-contact ms-lg-3 mt-4 mt-lg-0"
          >
          {{ this.lang === 'id' ? 'Hubungi Kami' : 'Contact Us' }}
          </b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'NavbarComponent',
  //accept data 'career' from parent component
  props: {
    links: {
      type: Array
    }
  },
  data() {
    return {
      isScroll: false,
      lang: localStorage.getItem('lang') || 'en',
      isLangActive: false,
    }
  },
  mounted() {
    this.handleLangStorage()
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 0) {
        return (this.isScroll = true)
      }

      return (this.isScroll = false)
    },
    handleLink(source) {
      this.closeNavbar()
      if (source === 'Blog') {
        return (window.location.href = 'https://blog.catsitterindonesia.com/')
      }

      if (source === 'Services') {
        if (this.$route.name !== 'Home') {
          this.$router.push({ name: 'Home' })
        }

        return this.$emit('trigger-services')
      }

      if (source === 'Contact') {
        return window.open(this.links.find((item) => item.type === 'whatsapp').url, '_blank')
      }

      if (source === 'Career') {
        return window.open(this.links.find((item) => item.type === 'career').url, '_blank')
      }

      return this.$router.push({ name: source })
    },
    handleLangStorage() {
      if (localStorage.getItem('lang')) {
        this.lang = localStorage.getItem('lang')
      }
      localStorage.setItem('lang', this.lang)
      this.isLangActive = this.lang === 'en'
    },
    handleLangOnChange() {
      this.closeNavbar()
      this.lang = this.isLangActive ? 'en' : 'id'
      localStorage.setItem('lang', this.lang)
      this.$emit('trigger-lang', this.lang)
    },
    closeNavbar() {
      if (window.innerWidth > 992) {
        return
      }

      document.querySelector('.navbar-toggler').click()
    }
  },
}
</script>

<style scoped>
nav.sticky {
  background: #fff;
}

nav .btn-contact {
  padding: 0.63vw 1.25vw;
  background: #fdb23f;
  color: #fff;
  font-weight: 600;
  font-size: 1.25vw;
  border: 0;
  border-radius: 2.5vw;
}

nav .btn-contact:hover,
nav .btn-contact:active {
  background: #fff;
  color: #ff5a2a;
}

nav.sticky .btn-contact {
  background: linear-gradient(90deg, #ff5a2a, #fdb23f);
  animation-direction: reverse;
}

nav.sticky .btn-contact:hover {
  background: linear-gradient(90deg, #ff5a2a, #ff5a2a) !important;
  color: #fff !important;
}

nav .lang p {
  font-size: 1.25vw;
  color: #fff;
}

nav.sticky .lang p {
  font-size: 1.25vw;
  color: #ff5a2a;
}

/* mobile */
@media (max-width: 991.98px) {
  nav .btn-contact,
  nav.sticky .btn-contact {
    padding: 3vw 9vw;
    background: #fdb23f;
    color: #f8eee2;
    font-weight: 600;
    font-size: 4vw;
    border: 0;
    text-align: center;
    border-radius: 10vw;
  }

  nav .lang p,
  nav.sticky .lang p {
    font-size: 4vw;
  }
}
</style>
