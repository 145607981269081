import { createRouter, createWebHistory } from "vue-router";

// Landing
import Home from "@/landing/pages/Home.vue";
import FAQ from "@/landing/pages/FAQ.vue";
import Profile from "@/landing/pages/Profile.vue";
import Contact from "@/landing/pages/Contact.vue";

// Admin
import Login from "@/admin/pages/Login.vue";
import Template from "@/admin/pages/Template.vue";
import Dashboard from "@/admin/pages/Dashboard.vue";
import Services from "@/admin/pages/Services.vue";
import Flows from "@/admin/pages/Flows.vue";
import Galleries from "@/admin/pages/Galleries.vue";
import Reasons from "@/admin/pages/Reasons.vue";
import Reviews from "@/admin/pages/Reviews.vue";
import Faqs from "@/admin/pages/Faqs.vue";
import Sitters from "@/admin/pages/Sitters.vue";
import Counts from "@/admin/pages/Counts.vue";
import Links from "@/admin/pages/Links.vue";
import Maintenance from "@/Maintenance.vue";
import Images from "@/admin/pages/Images.vue";

const token = localStorage.getItem("token");
const isLanding = window.location.hostname === "catsitterindonesia.com";
const isStaging = window.location.hostname === "staging.catsitterindonesia.com";
const isAdmin = window.location.hostname === "admin.catsitterindonesia.com";

const landingRoutes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: { title: "Cat Sitter Indonesia - Jasa Penitipan & Perawatan Kucing" },
	},
	{
		path: "/faq",
		name: "FAQ",
		component: FAQ,
		meta: { title: "Frequently Asked Questions | Cat Sitter Indonesia" },
	},
	{
		path: "/profile",
		name: "Profile",
		component: Profile,
		meta: { title: "Cat Sitter Profile | Cat Sitter Indonesia" },
	},
	{
		path: "/contact",
		name: "Contact",
		component: Contact,
		meta: { title: "Contact Us | Cat Sitter Indonesia" },
	},
	{
		path: "/:catchAll(.*)",
		redirect: "/",
	},
];

const adminRoutes = [
	{
		path: "/login",
		name: "Login",
		meta: { title: "Login - Admin | Cat Sitter Indonesia" },
		component: Login,
	},
	{
		path: "/",
		redirect: `${token ? "/dashboard" : "/login"}`,
		name: "Template",
		meta: { title: "Admin | Cat Sitter Indonesia" },
		component: Template,
		children: [
			{
				path: "/dashboard",
				name: "Dashboard",
				meta: { title: "Dashboard - Admin | Cat Sitter Indonesia" },
				component: Dashboard,
			},
			{
				path: "/counts",
				name: "Counts",
				meta: { title: "Counts - Admin | Cat Sitter Indonesia" },
				component: Counts,
			},
			{
				path: "/sitters",
				name: "Sitters",
				meta: { title: "Sitters - Admin | Cat Sitter Indonesia" },
				component: Sitters,
			},
			{
				path: "/services",
				name: "Services",
				meta: { title: "Services - Admin | Cat Sitter Indonesia" },
				component: Services,
			},
			{
				path: "/flows",
				name: "Flows",
				meta: { title: "Flows - Admin | Cat Sitter Indonesia" },
				component: Flows,
			},
			{
				path: "/galleries",
				name: "Galleries",
				meta: { title: "Galleries - Admin | Cat Sitter Indonesia" },
				component: Galleries,
			},
			{
				path: "/reasons",
				name: "Reasons",
				meta: { title: "Reasons - Admin | Cat Sitter Indonesia" },
				component: Reasons,
			},
			{
				path: "/reviews",
				name: "Reviews",
				meta: { title: "Reviews - Admin | Cat Sitter Indonesia" },
				component: Reviews,
			},
			{
				path: "/faqs",
				name: "FAQs",
				meta: { title: "FAQs - Admin | Cat Sitter Indonesia" },
				component: Faqs,
			},
			{
				path: "/links",
				name: "Links",
				meta: { title: "Links - Admin | Cat Sitter Indonesia" },
				component: Links,
			},
			{
				path: "/images",
				name: "Images",
				meta: { title: "Images - Admin | Cat Sitter Indonesia" },
				component: Images,
			},
		],
	},
	{
		path: "/:catchAll(.*)",
		redirect: "/",
	},
];

const maintenanceRoutes = [
	{
		path: "/",
		name: "Maintenance",
		meta: { title: "Maintenance | Cat Sitter Indonesia" },
		component: Maintenance,
	},
	{
		path: "/:catchAll(.*)",
		redirect: "/",
	},
];

const routes = isLanding ? landingRoutes : isAdmin ? adminRoutes : landingRoutes
// const routes = adminRoutes;

const router = createRouter({
	history: createWebHistory("/"),
	routes,
});

export default router;
