import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue3 } from "bootstrap-vue-3";
import VueSplide from "@splidejs/vue-splide";
import { createModal } from "@kolirt/vue-modal";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const app = createApp(App);
app.use(router);
app.use(BootstrapVue3);
app.use(VueSplide);
app.component("QuillEditor", QuillEditor);
app.use(
	createModal({
		transitionTime: 200,
		animationType: "slideDown",
		modalStyle: {
			padding: "5rem 2rem",
			align: "center",
			"z-index": 201,
		},
		overlayStyle: {
			"background-color": "rgba(0, 0, 0, .5)",
			"backdrop-filter": "blur(5px)",
			"z-index": 200,
		},
	})
);
app.mount("#app");
